import styled from "styled-components"
import { Button, Chip } from "@mui/material"
import CurrencyFormat from "react-currency-format"
import { handleBookmark } from "@/lib/strapi/userServices"
import { FaHeart, FaRegHeart } from "react-icons/fa"
import translate from "@/utils/translation"
import capitalizeFirstLetter from "@/utils/capitalizer"
import SplitBar from "@/components/common/Listing/splitBar/SplitBar"
import TagManager from "react-gtm-module"
import ListingStatus from "@/components/common/Listing/listingStatus.js/listingStatus"
import { useState } from "react"
import { useRouter } from "next-translate-routes"

const Item = ({ listing, session, update }) => {
  const [isHovered, setIsHovered] = useState(false)
  const router = useRouter()

  const totalRaisedAmount =
    listing?.splitInvestment?.finishedTranches
      ?.filter(item => item.status === "closed" && item?.type !== "bank")
      ?.reduce((acc, item) => acc + parseFloat(item.investmentAmount), 0) +
    parseFloat(listing?.splitInvestment?.investmentAmount)

  const investors =
    listing?.status === "bidding" &&
    listing?.splitInvestment?.totalInvestors > 0
      ? listing?.splitInvestment?.totalInvestors
      : 0

  return (
    <Wrapper
      onClick={() => (
        TagManager.dataLayer({
          dataLayer: {
            event: "listings_button_single",
            user_id: session?.uid,
            slug: listing?.slug,
            uid: listing?.uid,
          },
        }),
        session?.profile
          ? router.push(`/listings/${listing?.slug}`)
          : router.push("/auth/register")
      )}
      className="listing-item"
    >
      <ListingStatus listing={listing} />
      {session?.bookmarks?.find(item => item.id === listing.id) ? (
        <div className="bookmark clickable">
          <div
            className="heart active"
            onClick={() => handleBookmark(listing, session, update)}
          >
            <FaHeart color="#fb8500" size="18" border="" />
          </div>
        </div>
      ) : session ? (
        <div className="bookmark clickable">
          <div
            className="heart"
            onClick={() => handleBookmark(listing, session, update)}
          >
            <FaRegHeart color="#ebebeb" size="18" />
          </div>
        </div>
      ) : null}
      <div
        className="banner"
        style={{
          backgroundImage: `url(${listing?.building?.images?.banner?.formats?.small?.url})`,
        }}
      ></div>
      <div>
        <div className="pt-3 pe-3 ps-3 pb-0">
          <div className="d-flex objectType">
            {listing.splitInvestment?.enabled ? null : listing?.building
                ?.characteristics?.buildingType === "apartment" ? (
              <p>
                {translate("listings_overview_item_buildingType_apartment")}
              </p>
            ) : listing?.building?.characteristics?.buildingType === "house" ? (
              <p>{translate("listings_overview_item_buildingType_house")}</p>
            ) : (
              <p>
                {translate("listings_overview_item_buildingType_houseSplit")}
              </p>
            )}
          </div>
          <div className="d-flex mb-0 location justify-content-between">
            <div className="street mt-0">
              {listing?.project?.location?.streetName?.slice(0, 20)}
              {listing?.project?.location?.streetName?.length > 20 && ".."}{" "}
              {listing?.building?.characteristics?.locationAddition?.length > 0
                ? capitalizeFirstLetter(
                    listing?.building?.characteristics?.locationAddition
                  )
                : undefined}
            </div>
          </div>
          {!listing.splitInvestment?.enabled && (
            <div className=" d-flex">
              <span className="price">
                <CurrencyFormat
                  value={listing?.financials?.purchasePrice || 0}
                  displayType={"text"}
                  prefix={"€ "}
                  renderText={value => <div>{value}</div>}
                  decimalScale={0}
                  decimalSeparator=","
                  thousandSeparator="."
                />
              </span>
              <span className="subPrice">
                <CurrencyFormat
                  value={
                    parseFloat(listing?.financials?.purchasePrice) /
                      parseFloat(listing?.details?.totalSquareMeter) || 0
                  }
                  displayType={"text"}
                  suffix=" /m²)"
                  prefix={"(€ "}
                  renderText={value => <div>{value}</div>}
                  decimalScale={0}
                  decimalSeparator=","
                  thousandSeparator="."
                />
              </span>
            </div>
          )}
          {listing?.splitInvestment?.enabled && (
            <div className="splitProcess">
              <SplitBar listing={listing} />
              {investors === 0 && <div className="spacer" />}
            </div>
          )}
        </div>
        <hr />
        <div className="ps-3  pe-3 pb-0">
          <div className="details mb-3 mt-2">
            <div className="row">
              <div className="col-4">
                <div className="subtitle">
                  {listing?.splitInvestment?.enabled
                    ? translate("listing_single_amount")
                    : translate("listing_single_price")}
                </div>
                <div>
                  <CurrencyFormat
                    value={
                      parseFloat(totalRaisedAmount) ||
                      parseFloat(listing?.splitInvestment?.investmentAmount) ||
                      parseFloat(listing?.financials?.purchasePrice) ||
                      0
                    }
                    displayType={"text"}
                    prefix={"€ "}
                    renderText={value => <div>{value}</div>}
                    decimalScale={0}
                    decimalSeparator=","
                    thousandSeparator="."
                  />
                </div>
              </div>
              <div
                className={
                  listing?.splitInvestment?.enabled &&
                  listing?.splitInvestment?.loanInterestExitRate > 0
                    ? "col-4 ps-0 ms-auto"
                    : "col-4"
                }
              >
                <div className="subtitle">
                  {listing?.splitInvestment?.enabled
                    ? translate("listing_single_split_return")
                    : translate("listing_single_return")}
                </div>
                <div className="highlight">
                  {listing?.splitInvestment?.enabled ? (
                    <>
                      {listing?.splitInvestment?.loanInterestRate?.toFixed(2) ||
                        0}
                      %
                      {listing?.splitInvestment?.loanInterestExitRate > 0 && (
                        <span className="extra-interest">
                          {" +" +
                            (listing?.splitInvestment?.loanInterestExitRate?.toFixed(
                              0
                            ) || 0)}
                          %
                        </span>
                      )}
                    </>
                  ) : (
                    <>{listing?.financials?.NAR.toFixed(1)}%</>
                  )}
                </div>
              </div>
              <div className="col-4 pe-0">
                {listing?.splitInvestment?.enabled ? (
                  <>
                    <div className="subtitle">LTV</div>
                    <div className="highlight">
                      {listing?.splitInvestment?.trancheLtv > 75
                        ? 75
                        : listing?.splitInvestment?.trancheLtv > 0
                        ? listing?.splitInvestment?.trancheLtv
                        : 75}
                      %
                    </div>
                  </>
                ) : (
                  <>
                    <div className="subtitle">
                      {translate("listing_single_summarybox_BAR")}
                    </div>
                    <div className="highlight">
                      {listing?.financials?.BAR.toFixed(1) || 5}%
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="pb-3">
            {listing?.status === "sold" ? (
              <>
                <Button
                  className="w-100"
                  variant="outlined"
                  style={{
                    cursor: "pointer",
                  }}
                >
                  {translate("listing_single_sold")}
                </Button>
              </>
            ) : (
              <>
                <Button
                  className="w-100 invest_button"
                  variant="contained"
                  style={{
                    cursor: "pointer",
                  }}
                >
                  {listing.splitInvestment?.enabled
                    ? translate("listing_single_invest")
                    : translate("listing_single_buy")}
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default Item

/* Wrapper component */
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  background-color: white;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  transition: box-shadow 200ms;
  font-size: 0.875rem;
  position: relative;
  cursor: pointer;

  :hover {
    .invest_button {
      background: #fb8501;
    }
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  }

  .highlight {
    font-weight: 600;
  }

  .status {
    position: absolute;
    background: white;
    color: rgb(33, 47, 64);
    top: 10px;
    left: 10px;

    .split {
      padding: 0.2rem 0.4rem 0.2rem 0.2em;
    }
  }

  /* Banner styles */
  .banner,
  .split-banner {
    width: 100%;
    height: 200px;
    background-size: cover;
    border-radius: 5px 5px 0 0;
  }

  /* ObjectType styles */
  .objectType {
    p {
      color: #737780;
      line-height: 21px;
      font-size: 0.8rem;
      margin-bottom: 0;
    }
  }

  /* KPI styles */

  .subtitle {
    color: #737780;
  }

  hr {
    color: #c4bfbf;
    margin: 0.8rem;
  }

  /* Bookmark styles */
  .bookmark {
    position: absolute;
    right: 10px;
    top: 160px;

    .heart {
      background-color: rgba(0, 0, 0, 0.2);
      padding: 0.2rem 0.3rem 0.22rem;
      border-radius: 20%;

      svg {
        margin-bottom: 1px;
      }

      :hover {
        background-color: #fff3e5;
        svg {
          fill: #fb8500;
        }
      }
    }

    .active {
      background-color: #fff3e5;
    }
  }

  .price {
    font-size: 1rem;
    font-weight: 700;
    color: #fb8501;
  }

  /* Street styles */
  .street {
    color: #17233b;
    font-weight: 600;
    padding-right: 0.5rem;
    margin: auto 0;
    font-size: 0.875rem;
  }

  /* Price styles */
  .price {
    font-weight: 600;
  }

  .subPrice {
    font-size: 0.8rem;
    font-weight: 400;
    color: #737780;
    padding: 0.2rem 0 0 0.5rem;
  }

  /* Details styles */
  .details {
    font-size: 0.8rem;
    color: #454f62;

    padding: 0 0.2rem;

    div {
    }

    .item {
      margin-left: 0.5rem;

      :first-child {
        margin-left: 0;
      }
    }

    svg {
      margin: 0 5px 0 10px;

      :first-child {
        margin: 0 5px 0 0;
      }
    }
  }

  /* Divider styles */
  .divider {
    padding: 0;
    color: #ebebeb;
    font-size: 0.7rem;
    margin-top: 0.1rem;
  }

  .extra-interest {
    color: #1b9d46;
  }

  .invest_button {
    color: white;
    font-weight: 600;
    font-size: 0.8rem;
    border-radius: 5px;
    background: #fb8501d1;
  }

  /* City styles */
  .city {
    position: absolute;
    top: 150px;
    left: 5px;
    z-index: 20;
    color: white;
    border-radius: 5px;
    padding: 0.2rem 0.4rem;
    text-align: center;

    .MuiChip-root {
      border-radius: 5px;
    }
  }

  .spacer {
    padding: 0.5rem;
  }
`
